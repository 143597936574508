<template>
  <div v-show="isAllowed" @click="openModal" class="btn btn-primary--inverse">
    <img class="mr-2" src="@/assets/img/icons/cancel.svg" />
    <span>{{ buttonName }}</span>
  </div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'CANCEL',
      actionType: 'RENT_TRANSACTION',
    };
  },
  computed: {},
  methods: {
    async openModal() {
      // this.$modal.show('modal--cancel-booking', {
      //   uuid: this.applicationUuid,
      // });

      this.$modal.show('rent-reject-modal', {
        uuid: this.applicationUuid,
        buttonName: this.buttonName,
      });
      // this.$swal({
      //   title: this.$i18n.t('mybooking.cancelRentTitle'),
      //   text: this.$i18n.t('mybooking.cancelRentMessage'),
      //   type: 'error',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: this.$i18n.t('modal.yes'),
      //   cancelButtonText: this.$i18n.t('modal.no'),
      //   allowOutsideClick: false,
      // }).then(async result => {
      //   if (result.value) {
      //     try {
      //       this.loading = true;
      //       await this.$store.dispatch('v2/rentTransaction/cancel', this.applicationUuid);
      //       await this.$store.dispatch('v2/booking/detail/getData');
      //
      //       // eslint-disable-next-line no-empty
      //     } catch (e) {
      //     } finally {
      //       this.loading = false;
      //     }
      //   }
      // });
    },
  },
};
</script>
